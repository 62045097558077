export const redirectTo = (href: string) => {
  if (typeof window === "undefined") return;
  window.location.href = href;
};

export const containPromoHref = (href: string) => {
  const regex = /\/promo-dan-penawaran(?:\/.*)?/;

  const containsPromo = regex.test(href);

  return containsPromo;
};
